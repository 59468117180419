<template>
  <div>
    <img width="200" height="200" src="../assets/logo.svg" alt="piebits logo" />
    <h1>PIEBITS</h1>
    <p>comming soon ...</p>
    <a href="https://github.com/piebits-org">Check Out Piebits on Github</a>
    <div style="margin-top: 100px">
      <input v-model="email" placeholder="Enter Email" />
      <button @click="subscribe">Subscribe{{subscribed ? 'd' : ''}} for Newsletter</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data() {
    return {
      email: null,
      subscribed: false
    }
  },
  methods: {
    subscribe() {
      this.subscribed = true
    }
  }
}
</script>

<style scoped>
div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1
}

h1 {
  font-weight: 800;
  font-size: 4.2rem;
}

p {
  font-size: 2rem;
  opacity: 0.8;
  font-weight: 600;
}

input {
  height: 60px;
  width: 240px;
  border-radius: 15px;
  background-color: #EEEEEE;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 1rem;
  outline: none;
  font-weight: 600;
  letter-spacing: 0.05rem;
  margin: 10px;
}

a {
  font-weight: 600;
}

a:link {
  color: #26C6DA;
}

a:visited {
  color: #26C6DA;
}

button {
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  font-weight: 600;
  font-size: 1rem;
  background-color: #42A5F5;
  color: white;
  border-radius: 10px;
  margin: 10px;
  outline: none;
}
</style>
